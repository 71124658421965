.page {
  padding-top: 1rem;
  text-align: center;
}

.subscriptionContainer {
  max-width: 450px;
  text-align: center;
  border-radius: 2px;
  margin: 0 auto 2rem;
  padding-top: 0.5rem;
  // @media only screen and (min-width: 576px) {
  //   border: 1px lightgray solid;
  // }
}

.priceContainer {
  background-color: rgb(247, 247, 247);
  border-radius: 2px;
  margin: 0 10%;
}

.price {
  font-size: 50px;
  color: #1da57a;
}

.pricePeriod {
  font-size: 25px;
}

.featuresContainer {
  padding-top: 0.5rem;
  margin-bottom: 1rem;
}

.feature {
  margin-top: 0.5rem;
}
