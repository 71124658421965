@colum-width-lg: 100px;
@logo-width-lg: 38px;
@logo-height-lg: auto;
@colum-width-sm: 64px;
@logo-width-sm: 48px;
@logo-height-sm: 15px;
@screen-sm: 576px;

.container {
  padding-top: 1rem;
}

.freeValuePlay {
  border-width: 1px;
  border-style: solid;
  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  &:only-child {
    border-radius: 5px;
  }
}

.freeValuePlayBody {
  display: flex;
  align-items: center;
}

.freeValuePlayHeader {
  background-color: #f0f2f5;
  font-weight: 600;
  color: gray;
  padding-left: 0.5rem;
  display: flex;
  min-height: 2rem;
}

.headerButtonContainer {
  flex-grow: 1;
  text-align: right;
  padding-right: 0.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profit {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 700;
}

.leagueName {
  padding: 0 1rem;
}

.outcome {
  display: flex;
  align-items: center;
  height: 3rem;
}

.participantName {
  font-size: 1rem;
  flex: 0 0 15em;
}

.infoContainer {
  width: 50px;
  text-align: center;
  background-color: #f0f2f5;
  border-radius: 2px;
}

.info {
  font-weight: 700;
  line-height: 38px;
}

.qualifier {
  font-size: 10px;
  line-height: 2;
  font-weight: 700;
  letter-spacing: -1px;
}

.qualifier ~ .info {
  line-height: 18px;
}

.bookLogo {
  width: @logo-width-sm;
  height: @logo-height-sm;
  border-radius: 2px;
  object-fit: cover;
  @media only screen and (min-width: @screen-sm) {
    width: @logo-width-lg;
    height: @logo-height-lg;
    margin-right: 4px;
  }
}

.oddsContainer {
  width: @colum-width-sm;
  padding: 0.25rem 0.25rem;
  @media only screen and (min-width: @screen-sm) {
    width: @colum-width-lg;
  }
}

.odds {
  display: flex;
  background-color: #f0f2f5;
  padding: 0.25rem;
  border-radius: 2px;
  flex-direction: column;
  @media only screen and (min-width: @screen-sm) {
    flex-direction: row;
  }
}

.competitorContainer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.competitorName {
  flex: 1 1 30%;
  min-width: 30%;
  padding: 0.5rem;
  margin-right: auto;
  font-size: 1rem;
  font-weight: 600;
  // width: 11rem;
}

.betAmount {
  font-size: 0.75rem;
}

.competitorsContainer {
  flex-grow: 1;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.percent {
  font-size: 0.5rem;
  color: gray;
  text-align: right;
  line-height: 0.2;
}

.teams {
  font-size: 0.75rem;
  margin-top: -0.1rem;
}
