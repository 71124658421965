#menuItem {
  margin: 0 0.25rem;
  padding: 0 1rem;
}

.topLevelItem {
  margin: 0 !important;
}

:global(.ant-menu-submenu).topLevelItem :global(.ant-menu-submenu-title) {
  margin: 0;
}

:global(.ant-menu-submenu).topLevelItem :global(.ant-menu-item) {
  margin: 0;
}

.newTag {
  background-color: gold;
  color: white;
  position: absolute;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
  width: 1.5rem;
  text-align: center;
  border-radius: 4px;
  top: 12px;
  left: 100px;
  @media only screen and (min-width: 626px) {
    top: 13px;
    left: 43px;
  }
}

:global(.ant-menu-item).topLevelItem {
  margin: 0 !important;
}

:global(.ant-menu-inline) {
  height: unset !important;
}
