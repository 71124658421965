.leaderboardPage {
  padding: 1rem;
}

.container {
  max-width: 500px;
  margin: auto;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  & > div {
    padding: 0 0.25rem;
  }

  &.eliminated {
    opacity: 0.5;
  }
}

.itemName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  text-align: left;
  padding: 0 0.5rem 0 0;
  line-height: 1;
  & > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.itemPoints {
  color: #2c9b8e;
  font-size: 1.4rem;
  margin-left: auto;
}

.trackYourself {
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 2;
  height: 70px;
  width: 100%;
  justify-content: center;
  background-color: #6d72c3;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

#searchButton {
  background-color: #6d72c3;
}

.trackYourselfModal {
  inset: 40px;
  margin: auto;
  background-color: white;
  border: 1px solid white;
  height: 100vh;
  text-align: center;

  & > h2 {
    margin: 2rem 0 0.5rem;
  }

  & > form > div {
    margin: 1rem 0 0.5rem;
  }
}

.tiebreaker {
  font-size: 0.75rem;
  color: gray;
  line-height: 1;
  margin-top: 0.25rem;
}

.cardContainer {
  margin: 0.5rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
