@menu-change-width: 862px;

.header {
  padding-inline: 0 !important;
  z-index: 1;
}

.logo {
  float: left;
  width: 125px;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.rightContainer {
  margin-left: auto;
  display: flex;
}

.mobileMenuButton {
  display: flex;
  justify-content: center;
  width: 64px;
  height: 64px;
  text-align: center;
  cursor: pointer;
  @media only screen and (min-width: @menu-change-width) {
    display: none;
  }
}

.accountButton {
  display: none;
  margin-right: 1rem;
  @media only screen and (min-width: @menu-change-width) {
    display: unset;
  }
}

.tipsButton {
  display: none;
  margin-right: 1rem;
  @media only screen and (min-width: @menu-change-width) {
    display: unset;
  }
}

.darkIconButton {
  @media only screen and (min-width: @menu-change-width) {
    margin-right: 1rem;
  }
}

.upgradeButton {
  background-color: transparent !important;
  border-color: #f8a62c !important;
  color: #f8a62c !important;
}

.loginButton {
  position: absolute;
  top: 0;
  right: 1.5rem;
}

.menu {
  display: none !important;
  border-bottom: none !important;
  @media only screen and (min-width: @menu-change-width) {
    display: inline-block !important;
  }
}
