.entryPage {
  max-width: 500px;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.avatar > div > div > span > span {
  font-size: 2rem;
}

.name {
  margin-top: 0.5rem;
}

.questionAndAnswer {
  text-align: left;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.question {
  font-weight: 700;
}

.answer {
  margin-top: 0.25rem;
}
