@colum-width-lg: 100px;
@logo-width-lg: 38px;
@logo-height-lg: auto;
@colum-width-sm: 64px;
@logo-width-sm: 48px;
@logo-height-sm: 15px;
@logo-width-other-odds: 20px;
@logo-height-other-odds: 12px;
@screen-sm: 576px;
@screen-md: 768px;
@min-enhanced-experience: 1023px;

.container {
  padding-top: 1rem;
}

.market {
  border-width: 1px;
  border-style: solid;
  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  &:only-child {
    border-radius: 5px;
  }
}

.marketBody {
  display: flex;
  align-items: center;
}

.marketHeader {
  font-weight: 600;
  padding-left: 0.5rem;
  display: flex;
  min-height: 2rem;
}

.headerButtonContainer {
  display: flex;
  align-self: center;
  margin-left: auto;
  padding-right: 0.5rem;
  font-size: 1.25rem;
  div {
    padding: 0 0.25rem;
  }
}

.pinButton {
  display: none;
  @media only screen and (min-width: @min-enhanced-experience) {
    display: block;
  }
}

.hideButton {
  display: none;
  @media only screen and (min-width: @min-enhanced-experience) {
    display: block;
  }
}

.profit {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 700;
}

.leagueName {
  padding: 0 1rem;
}

.outcome {
  display: flex;
  align-items: center;
  height: 3rem;
}

.participantName {
  font-size: 1rem;
  flex: 0 0 15em;
}

.infoContainer {
  width: 48px;
  text-align: center;
  background-color: #f0f2f5;
  border-radius: 2px;
}

.info {
  font-weight: 700;
  line-height: 38px;
}

.qualifier {
  font-size: 10px;
  line-height: 2;
  font-weight: 700;
  letter-spacing: -1px;
}

.qualifier ~ .info {
  line-height: 18px;
}

.bookLogo {
  width: @logo-width-sm;
  height: @logo-height-sm;
  border-radius: 2px;
  object-fit: cover;
  @media only screen and (min-width: @screen-sm) {
    width: @logo-width-lg;
    height: @logo-height-lg;
    margin-right: 4px;
  }
}

.oddsContainer {
  padding: 0.25rem;
}

.oddsAndLinkContainer {
  display: flex;
  border-radius: 2px;
  z-index: -1;
}

.linkSymbolContainer {
  display: flex;
  margin: 0.25rem 0.25rem 0.25rem 0;
  border-radius: 2px;
  width: 1rem;
  font-size: 10px;
  align-items: center;
  justify-content: center;
}

.odds {
  display: flex;
  padding: 0.25rem;
  border-radius: 2px;
  flex-direction: column;
  @media only screen and (min-width: @screen-sm) {
    flex-direction: row;
  }
}

.otherOddsContainer {
  border-radius: 2px;
  margin: 0.25rem;
  padding: 0 0.25rem;
  display: none;
  @media only screen and (min-width: @screen-md) {
    height: 46px;
    display: block;
  }
}

.otherOddsContainerSmall {
  border-radius: 2px;
  margin: 0.25rem;
  padding: 0 0.25rem;
  display: block;
  margin-right: 0.25rem;
  margin-left: auto;
  height: 46px;
  @media only screen and (min-width: @screen-md) {
    display: none;
  }
}

.otherOdds {
  flex-direction: column;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  height: 100%;
}

.competitorContainer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.competitorName {
  flex: 1 1 30%;
  min-width: 30%;
  padding: 0.5rem;
  margin-right: auto;
  font-size: 1rem;
  font-weight: 600;
}

.smallBookLogo {
  width: @logo-width-other-odds;
  height: @logo-height-other-odds;
  border-radius: 2px;
  object-fit: cover;
}

.otherBooksOdds {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  width: 60px;
  flex-grow: 1;
}

.otherBooksOddsInfo {
  font-size: 10px;
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
}

.betAmount {
  font-size: 0.75rem;
}

.competitorsContainer {
  flex-grow: 1;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.smallNumber {
  font-size: 0.5rem;
  text-align: right;
  line-height: 0.2;
}

.teams {
  font-size: 0.75rem;
  margin-top: -0.1rem;
}

.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: none;
  cursor: pointer;
}

.noStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.whoseWrongCheckbox {
  margin: 0 0.5rem 0 1rem;
  @media only screen and (min-width: @screen-sm) {
    margin: 0 1rem 0 2rem;
  }
}

.whoseWrong {
  text-align: center;
  font-size: 8px;
  align-self: center;
  @media only screen and (min-width: @screen-sm) {
    margin: 9px;
  }
}

.extraInfo {
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin-top: 0.1rem;
  font-size: 0.5rem;
}

.extraInfo > *:only-child {
  margin-left: auto;
}
