/* @import "~antd/dist/antd.less"; */
@screen-sm: 576px;

.header {
  background: unset;
  padding: unset;
}

.logo {
  float: left;
  width: 125px;
  margin-left: 1rem;
  margin-right: 1rem;
  background: rgba(255, 255, 255, 0.3);
}

.content {
  background-color: white;
  padding: 0px 1rem;
  margin: auto;
  min-height: 280px;
  @media only screen and (min-width: @screen-sm) {
    padding: 0px 1rem;
  }
}

#menuItem {
  margin: 0 0.25rem;
  padding: 0 1rem;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.pageLayout {
  padding: 0;
  min-height: 90vh;
  @media only screen and (min-width: @screen-sm) {
    padding: 0 1.5rem 0rem;
  }
}

.breadcrumbContainer {
  margin: auto;
  min-height: 1rem;
}

.breadcrumb {
  margin: 16px 1rem;
  @media only screen and (min-width: @screen-sm) {
    margin: 16px 0;
  }
}

h1 {
  margin-block-start: 1rem;
}

h2 {
  margin-block-start: 0rem;
}

h3 {
  margin-block-start: 0rem;
}

h4 {
  margin-block-start: 0rem;
}

h5 {
  margin-block-start: 0;
}
