.content {
  max-width: 1000px;
  text-align: center;
  font-size: 0.75rem;
}

.content a {
  color: gray;
  text-decoration: underline;
  white-space: nowrap;
}
