@colum-width-lg: 100px;
@logo-width-lg: 38px;
@logo-height-lg: auto;
@colum-width-sm: 64px;
@logo-width-sm: 48px;
@logo-height-sm: 15px;
@screen-sm: 576px;

.container {
  padding-top: 1rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.smallSportInput {
  @media only screen and (min-width: 500px) {
    display: none;
  }
}

.notSmallSportInput {
  display: none;
  @media only screen and (min-width: 500px) {
    display: block;
  }
}

.buttonContainer {
  margin-left: auto;
  padding-top: 0.7rem;
}

.refreshButton {
  margin-right: 0.5rem;
}

.nothingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  color: gray;
  font-size: 1rem;
}
