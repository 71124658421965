.articleLink {
  border: solid 1px lightgray;
  color: unset;
  padding: 0.5rem;
  border-radius: 2px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.contentContainer {
  width: 90%;
  display: inline-block;
  line-height: 1.5;
}

.title {
  font-weight: 600;
  text-transform: uppercase;
}

.description {
  font-size: 0.75rem;
}

.iconContainer {
  width: 10%;
  display: inline-block;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tableOfContents {
  @media only screen and (min-width: 992px) {
    display: none;
  }
}

.largeTableOfContents {
  display: none;
  @media only screen and (min-width: 992px) {
    display: block;
  }
}
