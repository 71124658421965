.container {
  > :global(.ant-row) {
    padding-bottom: 1rem;
  }

  :global(.ant-card-body) {
    padding: 1rem;
  }

  :global(.ant-card-bordered) {
    border-radius: 0.25rem;
    border: 1px solid #c8c8c8;
  }
}

.primaryColor {
  color: #1da57a;
}
