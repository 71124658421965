@min-enhanced-experience: 1023px;

.nothingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  color: gray;
  font-size: 1rem;
  text-align: center;
}

.container {
  @media only screen and (min-width: @min-enhanced-experience) {
    display: flex;
  }
}

.primaryList {
  padding: 0 1rem 1rem;

  @media only screen and (min-width: @min-enhanced-experience) {
    flex-grow: 8;
  }
}

.secondaryList {
  display: none;
  padding: 0.5rem 1rem 1rem 1rem;
  flex-grow: 3;
  align-self: flex-start;
  width: 350px;
  @media only screen and (min-width: @min-enhanced-experience) {
    display: unset;
  }
}

.divider {
  display: none;
  width: 1.5rem;
  height: auto;
  min-height: 100% !important;
  background-color: #f0f2f5;

  @media only screen and (min-width: @min-enhanced-experience) {
    display: unset;
  }
}

.pinnedTitle {
  font-size: 16px;
  font-weight: 600;
}

.pinnedMarket {
  font-size: 14px;
}

.pinnedListContainer {
  margin-top: 1rem;
}

.tabIconContainer {
  text-align: center;
  & span {
    margin: 0 0.5rem !important;
    font-size: 1rem;
  }
}

.hiddenListItem {
  border: solid 1px #f0f2f5;
  border-bottom: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  &:last-child {
    border-bottom: solid 1px #f0f2f5;
  }
}

.hiddenListItemShowIconContainer {
  margin-left: auto;
  font-size: 1rem;
}
