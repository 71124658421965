.container {
  :global(.ant-card-bordered) {
    border: 1px solid #dadada;
  }

  :global(.ant-card-body) {
    padding: 0.5rem;
  }
}

.content {
  text-align: center;
  max-width: 700px;
  margin: 1rem auto 0;
}

.stepsContainer {
  max-width: 600px;
  margin: 0 auto;

  :global(.ant-steps-vertical) {
    align-items: center;

    :global(.ant-steps-item) {
      width: 155px; //This is a little hacky I think
    }
  }
}

.postCard {
  .title {
    font-weight: 600;
    clear: both;
  }

  .description {
    font-size: 0.75rem;
  }

  margin-bottom: 0.5rem;
  width: 100%;
}

.placeholderCard {
  height: 60px;
  margin-bottom: 0.5rem;
  width: 100%;
}

.new {
  color: #1da57a;
  float: right;
  font-weight: 300;
  font-size: 0.75rem;
}
