.premiumButton {
  background-color: #f8a62c !important;
  border-color: #f8a62c !important;

  &:global(.ant-btn-ghost) {
    background-color: transparent !important;
    border-color: #f8a62c !important;
    color: #f8a62c !important;
  }
}
