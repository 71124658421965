.container {
  position: sticky;
  top: 0;
}

.optionsContainer {
  border-bottom: 1px solid #eee;
  padding: 1rem 0;
  min-height: 155px;
  display: flex;
}

.row {
  display: flex;
  align-items: flex-start;

  &:first-child {
    > div {
      padding-top: 0;
    }
  }

  > div {
    padding: 0.5rem 0;
  }
}

.moreButton {
  margin-left: auto;
}

.sectionContainer {
  padding: 1rem;
}

.optionsRadioGroup {
  margin-left: auto;
  padding-top: 1rem !important;

  @media only screen and (min-width: 650px) {
    padding-top: 0 !important;
  }
}

.bookeSelect {
  width: 104px;
  @media only screen and (min-width: 650px) {
    width: 130px;
  }
}

.formFieldTitle {
  font-size: 0.6rem;
  font-weight: 600;
  margin-bottom: 2px;
}

.stakeInput :global(.ant-input-number-group-addon) {
  padding: 0 4px;
}

.stakeInput input {
  padding: 0 4px;
}

.floatingOptions {
  position: absolute;
  right: 0;
  top: -3em;
  width: 100%;
}

.saveModalContainer {
  text-align: center;

  div {
    padding: 0.5rem 0;
  }
}

.filterForm {
  border-bottom: 1px solid black;
  padding-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }

  :global(.ant-form-item-label) {
    padding: 0;
  }

  :global(.ant-form-inline) {
    flex-wrap: nowrap;
  }
}
