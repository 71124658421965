.container {
  :global(.ant-card-bordered) {
    border: 1px solid #dadada;
  }

  :global(.ant-card-body) {
    padding: 0.5rem;
  }
}

.postCard {
  .title {
    font-weight: 600;
    clear: both;
  }

  .description {
    font-size: 0.75rem;
  }

  margin-bottom: 0.5rem;
  width: 100%;
}

.placeholderCard {
  height: 75px;
  margin-bottom: 0.5rem;
  width: 100%;

  :global(.ant-skeleton-title) {
    margin: 0;
  }

  :global(.ant-skeleton-paragraph) {
    margin: 0.2rem 0 0 0 !important;
    height: 40px;
    overflow: hidden;

    :nth-child(2) {
      width: 50%;
    }

    li {
      margin-top: 0.3rem !important;
    }
  }
}

.new {
  color: #1da57a;
  float: right;
  font-weight: 300;
  font-size: 0.75rem;
}
