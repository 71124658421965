@colum-width-lg: 100px;
@logo-width-lg: 38px;
@logo-height-lg: auto;
@colum-width-sm: 64px;
@logo-width-sm: 48px;
@logo-height-sm: 15px;
@screen-sm: 576px;

.container {
  padding-top: 1rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.buttonContainer {
  margin-left: auto;
  padding-top: 0.7rem;
}

.refreshButton {
  margin-right: 0.5rem;
}

.nothingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  color: gray;
  font-size: 1rem;
}

.sportsBookSelect {
  width: 95px;
  @media only screen and (min-width: @screen-sm) {
    width: 120px;
  }
}

.sportsBookSelectContainer {
  margin-right: 0.5rem;
  @media only screen and (min-width: @screen-sm) {
    margin-right: 1rem;
  }
}

.moreButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
  @media only screen and (min-width: @screen-sm) {
    margin-left: 1rem;
  }
}
