.container {
  // background-color: white;
  width: 100%;
  position: absolute;
  left: 0;
  top: 4rem;
}

.header {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  padding: 1rem 5%;
}

.headerHalf {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 350px;
  padding: 0.5rem 1.18rem;
}

.title {
  color: white !important;
  margin: 0 0 0.5rem 0 !important;
}

.subtitle {
  color: white;
  font-size: 1.25rem;
}

.signUpContainer {
  border-radius: 1rem;
  padding: 1rem 2rem;
  text-align: center;
}

.priceContainer {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

.price {
  color: #1da57a;
}

.pricePeriod {
  font-size: 1rem;
}

.featuresContainer {
  margin-bottom: 1rem;
}

.feature {
  margin-top: 1.5rem;
  line-height: 1rem;
}

.featuresTitle {
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: 2rem;
}

.features {
  width: 100%;
  padding: 1rem 5%;
}

.smallFeature {
  padding: 0.5rem 2rem;
  text-align: center;
}

.largeFeature {
  padding: 1.5rem 2rem;
  text-align: center;
}

.featureTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.featureDescription {
  font-size: 1.25rem;
  margin-top: 1rem;
}

.smallFeatureDivider {
  @media screen and (min-width: 992px) {
    display: none !important;
  }
}
